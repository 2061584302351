import Swiper, { Autoplay, Navigation, Pagination } from '@/swiper';

export default function mainVisualSlider() {
  if (!document.querySelector('.js-mv-slider')) { return; }
  const swiper = new Swiper('.js-mv-slider', {
    modules: [Autoplay, Navigation, Pagination ],
    loop: true,
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 0,
    speed: 1600,
    pagination: {
      el: ".p-top-mv-slider-nav__pagination",
    },
    navigation: {
      nextEl: '.p-top-mv-slider-nav__arrow-btn.-next',
      prevEl: '.p-top-mv-slider-nav__arrow-btn.-prev',
    },
    // grabCursor: true,
    autoplay: {
      delay: 8000,
      disableOnInteraction: false,
    },
    // breakpoints: {
    //   768: {//768以上
    //   }
    // }
  })

  const pauseBtn =  document.querySelector('.p-top-mv-slider-nav__pause')
  pauseBtn.addEventListener('click', function(){
    if (this.classList.contains('is-play')) {
      swiper.autoplay.start()
      this.classList.remove('is-play')
      this.textContent = '一時停止'
    } else {
      swiper.autoplay.stop()
      this.classList.add('is-play')
      this.textContent = '再生'
    }
  });

}


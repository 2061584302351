import * as u from '../utility'

export default function hamburgerToggle() {
  if (!document.querySelector('.js-nav-hamburger')) { return; }
  const hamBtn         = document.querySelector('.js-nav-hamburger')
  const hamMenuArea    = document.querySelector('.js-nav-hamburger-menu-area')
  const closeBtn       = document.querySelector('.js-nav-hamburger-close')

  hamburgerMenu(hamBtn, hamMenuArea, closeBtn)
}

export const menuToggle = {
  open: (btn, menuArea) => {
    btn.classList.add('is-active')
    btn.setAttribute('aria-label', 'メニューを閉じる')
    btn.querySelector('.l-header-hamburger-btn__label').textContent = '閉じる'
    btn.setAttribute('aria-label', 'メニューを閉じる')
    menuArea.classList.add('is-show')
  },
  close: (btn, menuArea) => {
    btn.classList.remove('is-active')
    btn.setAttribute('aria-label', 'メニューを開く')
    btn.querySelector('.l-header-hamburger-btn__label').textContent = 'メニュー'
    menuArea.classList.remove('is-show')
  }
}

export const hamburgerMenu = (hamBtn, hamMenuArea, closeBtn) => {
  const header = document.querySelector('.l-header')

  //ハンバーガーメニュー
  hamBtn.addEventListener('click', () => {
    if (!hamBtn.classList.contains('is-active')) {
      menuToggle.open(hamBtn, hamMenuArea)
      document.body.classList.add('is-non-scroll')
      header.classList.add('is-ham-menu-show')
    } else {
      menuToggle.close(hamBtn, hamMenuArea)
      document.body.classList.remove('is-non-scroll')
      header.classList.remove('is-ham-menu-show')
    }
  })

  //メニューを閉じる
  closeBtn.addEventListener('click', () => {
    menuToggle.close(hamBtn, hamMenuArea)
    document.body.classList.remove('is-non-scroll')
    header.classList.remove('is-ham-menu-show')
  })

  //ブレイクポイント切り替えでメニューを非表示にする
  const mql = window.matchMedia( u.breakPointPC );
  const breakpointChange = (e) => {
    if (e.matches) {//PCレイアウトになったらハンバーガーメニューを閉じる
      menuToggle.close(hamBtn, hamMenuArea)
      document.body.classList.remove('is-non-scroll')
    }
  }
  mql.addEventListener('change', breakpointChange);
}
export default function agreeCheck() {
  if (!document.querySelector('.js-checked-agree')) { return; }

  const checkbox = document.querySelector('.js-checked-agree')
  const btn      = document.querySelector('.js-checked-agree__btn')

  btn.setAttribute('disabled', 'disabled')

  const btnActiveToggle = (event) => {
    const target  = event.target
    if (target.checked) {
      btn.removeAttribute('disabled')
    } else {
      btn.setAttribute('disabled', 'disabled')
    }
  }

  checkbox.addEventListener('change', btnActiveToggle)
  checkbox.dispatchEvent(new Event('change'))
}

import Swiper, { Autoplay, Navigation, Pagination } from '@/swiper';

export default function snsInstagramSlider() {
  if (!document.querySelector('.js-instagram-slider')) { return; }
  const swiper = new Swiper('.js-instagram-slider', {
    modules: [Autoplay, Navigation, Pagination ],
    loop: true,
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 0,
    speed: 800,
    pagination: {
      el: ".p-top-instagram-slider-nav__pagination",
      clickable: true,
    },
    grabCursor: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    // breakpoints: {
    //   768: {//768以上
    //   }
    // }
  })

}


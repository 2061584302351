export default function innerLinkMovetoPage() {
  const headerHeight  = document.querySelector('.l-header').offsetHeight
  const hashurl       = location.hash
  const hash          = hashurl.slice(1);
  const target        = document.getElementById(hash);

  if(target) {
    const top = target.getBoundingClientRect().top + window.pageYOffset - headerHeight;
    setTimeout(function(){
      window.scrollTo({top: 0}, 0);
    });
    setTimeout(function(){
      window.scrollTo({
        top: top
      });
    });
  }
}

export default function pagetop() {
  if (!document.querySelector('.js-pagetop')) { return; }
  const btn = document.querySelector('.js-pagetop')
  btn.addEventListener('click', pagetopLink)

  const trigger = document.querySelector('.l-footer')
  //消えるエリアのどの付近で消えるか指定
  const options = {
    rootMargin: "-80% 0px 0px 0px"
  }

  //フッターに差し掛かると所定の位置に戻る
  const animation = (entries) => {
    const entry = entries[0]
    if (entry.isIntersecting) {
      btn.classList.remove('is-fixed');
    } else {
      btn.classList.add('is-fixed')
    }
  }

  const observer = new IntersectionObserver(animation, options)
  observer.observe(trigger);

  window.addEventListener('scroll', function(){
    const scroll = window.scrollY;
    if(scroll > 300) {
      btn.classList.add('is-show')
    } else {
      btn.classList.remove('is-show')
    }
  })
}

export const pagetopLink = () => {
  window.scroll({ top: 0, behavior: 'smooth' })
}

import Swiper, { Autoplay, Navigation, Pagination } from '@/swiper';

export default function snsYoutubeSlider() {
  if (!document.querySelector('.js-youtube-slider')) { return; }
  const swiper = new Swiper('.js-youtube-slider', {
    modules: [Autoplay, Navigation, Pagination ],
    loop: true,
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 29,
    speed: 800,
    // pagination: {
    //   el: ".p-top-youtube-slider-nav__pagination",
    // },
    navigation: {
      nextEl: '.p-top-youtube-slider-nav__arrow-btn.-next',
      prevEl: '.p-top-youtube-slider-nav__arrow-btn.-prev',
    },
    grabCursor: true,
    // autoplay: {
    //   delay: 8000,
    //   disableOnInteraction: false,
    // },
    // breakpoints: {
    //   768: {//768以上
    //   }
    // }
  })

}


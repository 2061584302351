export default function fixedBtns() {
  if (!document.querySelector('.js-fixed-btns')) { return; }

  const btn = document.querySelector('.js-fixed-btns')
  const trigger = document.querySelector('.l-footer')

  //消えるエリアのどの付近で消えるか指定
  const options = {
    rootMargin: "-80% 0px 0px 0px"
  }
  //フッターに差し掛かると所定の位置に戻る
  const animation = (entries) => {
    const entry = entries[0]
    if (entry.isIntersecting) {
      btn.classList.remove('is-show')
    } else {
      btn.classList.add('is-show')
    }
  }
  const observer = new IntersectionObserver(animation, options)
  observer.observe(trigger)

  let flag
  window.addEventListener('scroll', function(){
    const scroll = window.scrollY;
    if(scroll > 300) {
      if (!flag) {
        btn.classList.add('is-show')
        flag = true
      }
    } else {
      flag = false
      btn.classList.remove('is-show')
    }
  })
}

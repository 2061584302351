import {accordionToggle} from '../modules/accordionToggle'

export default function panelOpen() {
  if (!document.querySelector('.js-panel-open_btn')) { return; }
  
  const btn     = document.querySelector('.js-panel-open_btn')
  const panel   = document.querySelectorAll('.js-panel-open_panel')

  //ロードしたとき aria-expandedのtrue/falseで開閉を決める
  if (btn.getAttribute('aria-expanded') === 'false') {
    accordionToggle.hide(btn)
    btn.textContent = '開く'
  } else {
    accordionToggle.show(btn)
    btn.textContent = '閉じる'
  }

  //開閉ボタン
  btn.addEventListener('click', (e) => {
    acc(e.currentTarget)
  })

  const acc = (btn) => {
    if (btn.getAttribute('aria-expanded') === 'false') {
      accordionToggle.show(btn)
      btn.textContent = '閉じる'
    } else {
      accordionToggle.hide(btn)
      btn.textContent = '開く'
    }
  }

}

export const accordionToggle = {
  show: (btn) => {
    const accBtn = btn
    const panelId = btn.getAttribute('aria-controls')
    accBtn.setAttribute('aria-label', '閉じる')
    accBtn.setAttribute('aria-expanded', 'true')
    accBtn.classList.add('is-show')
    document.getElementById(panelId).classList.add('is-show')
  },
  hide: (btn) => {
    const accBtn = btn
    const panelId = btn.getAttribute('aria-controls')
    accBtn.setAttribute('aria-label', '開く')
    accBtn.setAttribute('aria-expanded', 'false')
    accBtn.classList.remove('is-show')
    document.getElementById(panelId).classList.remove('is-show')
  }
}

export default function scrollAnimation(selector = '.c-animation-block') {
  const els = document.querySelectorAll(selector)

  if ((els?.length ?? 0) < 0) { return }

  els.forEach((el) => {
    scrollAnimation_main(el)
  })
}

const scrollAnimation_main = (el) => {
  const rect = el.getBoundingClientRect() // 要素の寸法の取得
  const delay = parseInt(el.getAttribute('data-delay') ?? '0')
  const spDelay = parseInt(el.getAttribute('data-sp-delay') ?? delay)
  const breakpoint = parseInt(el.getAttribute('data-breakpoint') ?? '768')
  const matchMedia = window.matchMedia('(min-width: ' + breakpoint + 'px)')

  // リアクティブな変数
  let currentPosition = rect.top // 現在の位置（初期値：ターゲットとなる要素の上端位置）
  let targetPositon = window.innerHeight * 0.8 // 発火位置
  let mediaMatches = matchMedia.matches // メディアクエリ判定

  // ウインドウサイズが変更された場合
  matchMedia.addEventListener('change',  (event) => {
    mediaMatches = event.matches
  })

  // 初回ロード時のアニメーション判定
  if (currentPosition < targetPositon) {
    setTimeout(() => {
      el.classList.add('-active')
    }, mediaMatches ? delay : spDelay)
  }

  // スクロール時のアニメーション判定
  window.addEventListener('scroll', (e) => {
    const rect = el.getBoundingClientRect()
    currentPosition = rect.top

    if (currentPosition < targetPositon) {
      setTimeout(() => {
        el.classList.add('-active')
      }, mediaMatches ? delay : spDelay)
    }
  })
}
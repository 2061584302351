export default function mailChange() {
  if (!document.querySelector('.js-mail-change')) { return; }

  const mails = document.querySelectorAll('.js-mail-change')

  mails.forEach(mail => {
    const text1 = mail.dataset.name1
    const text2 = mail.dataset.name2
    const mailAddress = text1 + '@' + text2
    mail.textContent = mailAddress
  })
}

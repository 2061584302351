//scripts
import header from './layout/header'
import footer from './layout/footer'
import linkNoOpener from './modules/linkNoOpener'
import agreeCheck from './modules/agreeCheck'
import innerLinkSummery from './modules/innerLink'
import innerLinkMovetoPage from './modules/innerLink_movetoPage'
import mailChange from './modules/mailChange'
import scrollbarWidth from './modules/scrollBarWidth'
import accordion from './modules/accordion'
import modalSummery from './modules/modal'
import scrollAnimation from './modules/scrollAnimation'
import mainVisualSlider from './modules/mainVisualSlider'
import keywordSlider from './modules/keywordSlider'
import panelOpen from './modules/panelOpen'
import fixedBtns from './modules/fixedBtns'
import snsTiktokSlider from './modules/snsTiktokSlider'
import snsYoutubeSlider from './modules/snsYoutubeSlider'
import snsInstagramSlider from './modules/snsInstagramSlider'

window.addEventListener('DOMContentLoaded', () => {
  header();
  footer();
  linkNoOpener();
  innerLinkSummery();
  innerLinkMovetoPage();
  // agreeCheck();
  // mailChange();
  scrollbarWidth();
  // accordion();
  // modalSummery();
  scrollAnimation();
  mainVisualSlider();
  keywordSlider();
  panelOpen();
  fixedBtns();
  snsTiktokSlider();
  snsYoutubeSlider();
  snsInstagramSlider();
})
import Swiper, { Navigation, Scrollbar, Mousewheel } from '@/swiper';

export default function keywordSlider() {
  if (!document.querySelector('.js-keyword-slider')) { return; }
  const swiper = new Swiper('.js-keyword-slider', {
    modules: [Navigation,Scrollbar,Mousewheel],
    slidesPerView: "auto",
    spaceBetween: 10,
    // freeMode: true,
    // freeModeSticky: true,
    // speed: 2000,
    mousewheel: {
      forceToAxis: true
    },
    scrollbar: {
      el: '.p-top-keyword-slider-scrollbar',
      hide: false,
      draggable: true 
    },
    navigation: {
      nextEl: '.p-top-keyword-slider-nav__arrow-btn.-next',
      prevEl: '.p-top-keyword-slider-nav__arrow-btn.-prev',
    },
    // grabCursor: false,
    breakpoints: {
      768: {//768以上
        spaceBetween: 23,
      }
    }
  })


}


import MicroModal from '@/micromodal';

// 呼び出しクラス一覧
export default function modalSummery() {
  // モーダルの初期化は触らない
  modalInit()
  // ここまで：モーダルの初期化は触らない

  modalTriggerInit('.js-modal-trigger')
  modalGroupInit('.js-modal-group')
  modalGroupInit('.js-modal-group2', false)
}

// ここから下は基本的に触らない
/**
 * モーダルshow/close時のオプション
 */
const modalOption = {
  onShow: () => { document.body.style.overflow = 'hidden' },
  onClose: () => { document.body.style.overflow = 'initial' }
}

/**
 * モーダルの初期化
 */
export const modalInit = () => {
  MicroModal.init({
    disableScroll: true
  })
}

/**
 * モーダル開くボタンのイベント生成
 * （プラグインデフォルトの開くボタンだと、グループモーダル時にバグが発生するため）
 * @param {string | HTMLElement} jsClass クラス名
 * @returns
 */
export const modalTriggerInit = (jsClass) => {
  const els = document.querySelectorAll(jsClass)
  if ((els?.length ?? 0) <= 0) { return; }

  els.forEach((el) => {
    if (!el.value) { return }

    el.addEventListener('click', () => {
      MicroModal.show(el.value, modalOption)
    })
  })
}

/**
 * モーダルグループの前後遷移イベント生成
 * @param {string | HTMLElement} jsClass クラス名
 * @param {boolean} loopActive ループさせるか
 * @returns
 */
export const modalGroupInit = (jsClass, loopActive = true) => {
  const els = document.querySelectorAll(jsClass)
  if ((els?.length ?? 0) <= 0) { return; }

  els.forEach((el) => {
    // モーダル
    const modalEls = el.querySelectorAll('[aria-hidden]')
    if ((modalEls?.length ?? 0) <= 0) { return; }

    // グループ内の先頭・最後のモーダル
    const firstEl = modalEls[0]
    const lastEl = modalEls[modalEls.length - 1]

    modalEls.forEach((modalEl) => {
      // 前後モーダル
      const prevEl = modalEl?.previousElementSibling
      const nextEl = modalEl?.nextElementSibling
      // 前後ボタン
      const prevButtonEl = modalEl?.querySelector('.c-modal-group__prev')
      const nextButtonEl = modalEl?.querySelector('.c-modal-group__next')

      if (prevEl && prevButtonEl) {
        prevButtonEl.addEventListener('click', () => {
          MicroModal.close(modalEl.id, modalOption)
          MicroModal.show(prevEl.id, modalOption)
        })
      } else if (loopActive && lastEl && prevButtonEl) {
        prevButtonEl.addEventListener('click', () => {
          MicroModal.close(modalEl.id, modalOption)
          MicroModal.show(lastEl.id, modalOption)
        })
      } else if (prevButtonEl) {
        prevButtonEl.classList.add('-disabled')
      }

      if (nextEl && nextButtonEl) {
        nextButtonEl.addEventListener('click', () => {
          MicroModal.close(modalEl.id, modalOption)
          MicroModal.show(nextEl.id, modalOption)
        })
      } else if (loopActive && firstEl && nextButtonEl) {
        nextButtonEl.addEventListener('click', () => {
          MicroModal.close(modalEl.id, modalOption)
          MicroModal.show(firstEl.id, modalOption)
        })
      } else if (nextButtonEl) {
        nextButtonEl.classList.add('-disabled')
      }

    })
  })
}

import {accordionToggle} from '../modules/accordionToggle'

export default function accordion() {
  if (!document.querySelector('.js-acc')) { return; }
  
  const accWraps = document.querySelectorAll('.js-acc')

  accWraps.forEach(accWrap => {
    const accBtn     = accWrap.querySelector('.js-acc__btn')
    const accHeading = accWrap.querySelector('.js-acc__heading')

    //ロードしたとき aria-expandedのtrue/falseで開閉を決める
    if (accBtn.getAttribute('aria-expanded') === 'false') {
      accordionToggle.hide(accBtn)
    } else {
      accordionToggle.show(accBtn)
    }

    //開閉ボタン
    accBtn.addEventListener('click', (e) => {
      acc(e.currentTarget)
    })

    //見出しを押したとき
    accHeading.addEventListener('click', (e) => {
      const btn = e.currentTarget.closest('.js-acc').querySelector('.js-acc__btn')
      acc(btn)
    })

    const acc = (btn) => {
      if (btn.getAttribute('aria-expanded') === 'false') {
        accordionToggle.show(btn)
      } else {
        accordionToggle.hide(btn)
      }
    }
  })
}
